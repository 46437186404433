html,
body {
    scroll-behavior: smooth;

}

.wrapper {
    margin-top: 2rem;
    width: 100%;
    overflow-x: hidden;
}

.container {
    width: 100%;
    margin: auto;
    height: 100%;
}

.navTextStyle {
    font-family: "Poppins";
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
}

.gradientBorderLeftBox {
    position: relative;
    width: 376px;
    height: 222px;
    border: none;
}
/* Pseudo-element for the gradient border */
.gradientBorderLeftBox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top-left-radius: 70px;

    /* Gradient border */
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    background: linear-gradient(180deg, #12C395 0%, #FFF8FC 100%) border-box;
    mask:
      linear-gradient(#fff, #fff) padding-box,
      linear-gradient(#fff, #fff);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    /* Show only top and left borders */
    clip-path: polygon(0 0, 100% 0, 0 100%, 0 0);
    pointer-events: none;
  }

.gradientBorderRightBox {
    position: relative;
    width: 376px;
    height: 222px;
    border: none;
}
/* Pseudo-element for the gradient border */
.gradientBorderRightBox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top-right-radius: 70px;

    /* Gradient border */
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    background: linear-gradient(180deg, #12C395 0%, #FFF8FC 100%) border-box;
    mask:
      linear-gradient(#fff, #fff) padding-box,
      linear-gradient(#fff, #fff);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    /* Show only top and left borders */
    clip-path: polygon(100% 0, 100% 100%, 0 0, 100% 0);
    pointer-events: none;
  }

.footerListMainText {
    font-family: "Poppins";
    color: rgba(18, 195, 149, 1);
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0%;
    margin-bottom: 10px;
}

.footerListSubText {
    font-family: "Poppins";
    font-weight: 300;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0%;
}

@media(max-width:500px) {
    .wrapper {
        margin-top: 4.313rem;
    }
}